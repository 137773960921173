import { $axios } from '../config/axios'
import {
  PeerClass,
  RegPeerClass,
  ResPeerClass,
  ResCategory,
  ReqCategory,
  ReqLinkedCategory,
  ResLinkedCategory,
} from '@/model/competitiveAnalysis/peerData/peerData'
import {
  ReqCompetitorAccount,
  ResCompetitorAccount,
  CompetitorAccount,
} from '@/model/competitiveAnalysis/peerData/competitorAccount'
import { ReqCompetingGoods } from '@/model/competitiveAnalysis/competingGoodsData/competingGoods'
class AccompanyApi {
  //同行信息展示
  AccompanyList(data: RegPeerClass): Promise<ResPeerClass> {
    return $axios.post('/api/accompany/list', data)
  }
  //新增同行信息
  AccompanyAdd(data: PeerClass): Promise<unknown> {
    return $axios.post('/api/accompany/add', data)
  }
  //删除同行信息
  AccompanyDel(data: unknown): Promise<unknown> {
    return $axios.post('/api/accompany/delete', data)
  }
  //修改同行信息
  AccompanyUpdate(data: PeerClass): Promise<unknown> {
    return $axios.post('/api/accompany/update', data)
  }
  //通过id获取详细信息
  AccompanyGetInfo(data: unknown): Promise<PeerClass> {
    return $axios.post('/api/accompany/getInfo', data)
  }
  //同行账户展示
  CompetitorAccountList(data: ReqCompetitorAccount): Promise<ResCompetitorAccount> {
    return $axios.post('/api/competitorAccount/list', data)
  }
  //新增同行账户
  CompetitorAccountAdd(data: CompetitorAccount): Promise<CompetitorAccount> {
    return $axios.post('/api/competitorAccount/add', data)
  }
  //新修改同行账户
  CompetitorAccountUpdate(data: CompetitorAccount): Promise<CompetitorAccount> {
    return $axios.post('/api/competitorAccount/update', data)
  }
  //删除同行账户
  CompetitorAccountDelete(data: unknown): Promise<unknown> {
    return $axios.post('/api/competitorAccount/delete', data)
  }
  //删除同行账户
  CompetitorAccountGetInfo(data: unknown): Promise<CompetitorAccount> {
    return $axios.post('/api/competitorAccount/getInfo', data)
  }
  //已关联类别分页
  LinkedCategoryPage(data: ReqLinkedCategory): Promise<ResLinkedCategory> {
    return $axios.post('/api/accompany/linkedCategoryPage', data)
  }
  //物料类别同行关联
  AddCategoryRelatedPeers(data: unknown): Promise<unknown> {
    return $axios.post('/api/accompany/addCategoryRelatedPeers', data)
  }
  //物料类别同行关联移除
  RemoveCategoryRelatedPeers(data: unknown): Promise<unknown> {
    return $axios.post('/api/accompany/removeCategoryRelatedPeers', data)
  }
  //erp物料类别分页展示
  CategoryPage(data: ReqCategory): Promise<ResCategory> {
    return $axios.post('/api/product/categoryPage', data)
  }
  //批量导入
  CompeteImport(data: unknown): Promise<unknown> {
    return $axios.post('/api/compete/import', data)
  }
}
// 单列模式返回对象
let accompanyApi
export default (() => {
  if (accompanyApi) return accompanyApi
  accompanyApi = new AccompanyApi()
  return accompanyApi
})()
